// src/pages/FAQ.js
import React from 'react';
import { Box, Container, Typography, Accordion, AccordionSummary, AccordionDetails, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Header from '../components/Header';
import Footer from '../components/Footer';

const faqData = [
  {
    category: 'General Questions',
    questions: [
      {
        question: 'What services do you offer?',
        answer: 'We offer a wide range of services including mobile and web development, cloud solutions, CRM systems, and more.',
      },
      {
        question: 'How can I get in touch?',
        answer: 'You can reach us through our contact page, email, or phone. Visit the contact section for more details.',
      },
    ],
  },
  {
    category: 'Pricing & Payment',
    questions: [
      {
        question: 'What payment methods do you accept?',
        answer: 'We accept various payment methods, including credit/debit cards, bank transfers, and digital payments.',
      },
      {
        question: 'Do you offer any discounts?',
        answer: 'Yes, we offer seasonal discounts and customized packages for long-term projects. Contact us for more information.',
      },
    ],
  },
  {
    category: 'Technical Support',
    questions: [
      {
        question: 'What kind of technical support do you provide?',
        answer: 'We provide 24/7 support for active projects and regular maintenance post-delivery to ensure smooth functionality.',
      },
      {
        question: 'How can I request a feature update?',
        answer: 'For feature requests, reach out to our support team or submit a request via the client portal.',
      },
    ],
  },
];

const FAQ = () => {
  return (
    <>
      <Header />
      <Box sx={{ backgroundColor: '#f5f5f5', padding: '7rem 0' }}>
        <Container maxWidth="md">
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#1976d2', textAlign: 'center', marginBottom: '2rem' }}>
            Frequently Asked Questions
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center', marginBottom: '3rem' }}>
            Have questions? Find answers to the most common questions below. If you need further assistance, feel free to contact us.
          </Typography>

          {faqData.map((category, catIndex) => (
            <Box key={catIndex} sx={{ marginBottom: '2rem' }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1976d2', marginBottom: '1rem' }}>
                {category.category}
              </Typography>
              {category.questions.map((item, index) => (
                <Accordion key={index} sx={{ boxShadow: 'none', backgroundColor: 'white' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#e3f2fd' }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {item.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2" color="textSecondary">
                      {item.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          ))}
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default FAQ;
