// src/Services/WordPressDevelopment.js
import React from 'react';
import { Box, Container, Typography, Grid, Button, Card, CardContent, TextField } from '@mui/material';
import { Web, Security, DesignServices, Speed } from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import wordpressImage from '../assest/Wordpress Development.webp';

const WordPressDevelopment = () => {
  return (
    <>
      <Header />
      <Box sx={{ backgroundColor: '#f9f9f9', padding: '5rem 0' }}>
        <Container maxWidth="lg">
          {/* Hero Section */}
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', gap: 3, mb: 4 }}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#1976d2', mb: 2 }}>
                WordPress Development
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                At Deeva Payon, we provide tailored WordPress solutions that fit your business needs perfectly. Our experienced team delivers customized, feature-rich WordPress sites that offer smooth functionality, excellent user experiences, and flexibility for future growth.
              </Typography>
              <Button variant="contained" color="secondary" size="large" sx={{ mt: 3 }} href="/contact">
                Start Your Project
              </Button>
            </Box>
            <Box sx={{ flex: 1 }}>
              <img src={wordpressImage} alt="WordPress Development" style={{ width: '100%', borderRadius: '10px' }} />
            </Box>
          </Box>

          {/* Key Features Section */}
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2', textAlign: 'center', mb: 3 }}>
            Our WordPress Development Services
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <DesignServices fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Custom Theme Development</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                    We create custom themes tailored to your brand identity and design preferences, ensuring a unique and consistent user experience across all pages.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Security fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Secure and Reliable</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                    Our development process prioritizes security, keeping your site protected from threats while maintaining performance and reliability.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Web fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Plugin Development & Integration</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                    We offer custom plugin development and seamless integration, adding functionality to enhance user experience and meet business objectives.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Speed fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Performance Optimization</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                    We optimize your site for speed and performance, delivering faster load times and improved search engine rankings.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Why Choose Us Section */}
          <Box sx={{ mt: 5, textAlign: 'center' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Why Choose Deeva Payon for WordPress Development?</Typography>
            <Typography variant="body1" color="textSecondary" paragraph sx={{ mt: 1, mb: 3 }}>
              Our expertise in WordPress development guarantees high-quality, scalable, and future-ready solutions. We emphasize performance, security, and customization, offering a site that grows with your business.
            </Typography>
            <Button variant="contained" color="secondary" size="large" href="/contact">Start Your WordPress Project</Button>
          </Box>

          {/* Contact Form Section */}
          <Box sx={{ mt: 6, py: 4, backgroundColor: '#e3f2fd', borderRadius: '8px' }}>
            <Container maxWidth="sm">
              <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 2 }}>
                Get In Touch
              </Typography>
              <form>
                <TextField label="Name" fullWidth margin="normal" variant="outlined" />
                <TextField label="Email" fullWidth margin="normal" variant="outlined" />
                <TextField label="Phone" fullWidth margin="normal" variant="outlined" />
                <TextField label="Message" fullWidth multiline rows={4} margin="normal" variant="outlined" />
                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                  Submit
                </Button>
              </form>
            </Container>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default WordPressDevelopment;
