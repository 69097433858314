// src/Services/BlogWebsiteDevelopment.js
import React from 'react';
import { Box, Container, Typography, Grid, Button, Card, CardContent, TextField } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import blogDevImage from '../assest/Blog Website Development.png';

const BlogWebsiteDevelopment = () => {
  return (
    <>
      <Header />
      <Box
        sx={{
          backgroundColor: '#f9f9f9',
          padding: { xs: '5rem 1rem', md: '7rem 0' },
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="lg">
          {/* Hero Section */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              gap: 4,
              marginBottom: '3rem',
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#1976d2', marginBottom: 2 }}>
                Blog Website Development
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                At Deeva Payon, we design and build blog websites that empower writers, brands, and businesses to connect with audiences through engaging and responsive content. Our team focuses on providing user-friendly and SEO-optimized platforms that help your voice reach far and wide.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                sx={{ marginTop: 3 }}
                href="/contact"
              >
                Start Your Blog Journey
              </Button>
            </Box>
            <Box sx={{ flex: 1 }}>
              <img
                src={blogDevImage}
                alt="Blog Website Development"
                style={{ width: '100%', borderRadius: '10px' }}
              />
            </Box>
          </Box>

          {/* Services Offered */}
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bold', color: '#1976d2', textAlign: 'center', marginBottom: '2rem' }}
          >
            What We Offer
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  transition: '0.3s',
                  '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' },
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Responsive Design
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    We create blog websites that are visually appealing and accessible on any device, ensuring a consistent and engaging user experience across platforms.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  transition: '0.3s',
                  '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' },
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    SEO Optimization
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    Our blogs are built with search engine optimization in mind, helping you rank higher and attract more readers organically.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  transition: '0.3s',
                  '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' },
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    CMS Integration
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    We integrate powerful Content Management Systems (CMS) like WordPress, giving you the control to easily manage and publish your content.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  transition: '0.3s',
                  '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' },
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Customizable Templates
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    Our blog designs are fully customizable, allowing you to choose a layout and theme that aligns with your brand's identity and vision.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Why Choose Us */}
          <Box sx={{ marginTop: '4rem', textAlign: 'center' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
              Why Choose Deeva Payon for Blog Development?
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph sx={{ marginTop: '1rem', marginBottom: '3rem' }}>
              With a deep understanding of the blogging ecosystem, we deliver professional solutions that cater to writers, influencers, and brands alike. Our approach to blog development focuses on flexibility, scalability, and optimization to help you build a thriving community.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              href="/contact"
            >
              Start Your Blog with Us
            </Button>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default BlogWebsiteDevelopment;
