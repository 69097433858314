import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../CartContext.js';
import { Box, Typography, TextField, Button, Divider, CardMedia, List, Dialog, DialogTitle, DialogContent, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { keyframes } from '@mui/system';
import b2bSoftwareImage from '../assest/B2B Software Solutions.png'; 

const Purchase = () => {
  const { cartItems, setCartItems } = useContext(CartContext);
  const navigate = useNavigate();

  const [shippingDetails, setShippingDetails] = useState({
    name: '',
    address: '',
    contact: '',
    email: '',
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [gst, setGst] = useState(0);
  const [isPaymentDialogOpen, setPaymentDialogOpen] = useState(false);

  // Calculate total price and GST
  useEffect(() => {
    const total = cartItems.reduce((sum, item) => sum + item.price, 0);
    setTotalPrice(total);

    // Calculate 18% GST on total
    setGst(total * 0.18);
  }, [cartItems]);

  const handleInputChange = (e) => {
    setShippingDetails({
      ...shippingDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleConfirmPurchase = () => {
    if (shippingDetails.name && shippingDetails.address && shippingDetails.contact && shippingDetails.email) {
      setPaymentDialogOpen(true);
    } else {
      alert('Please fill in all Address details.');
    }
  };

  const handlePayment = () => {
    setCartItems([]);
    setPaymentDialogOpen(false);
    alert('Payment successful! Thank you for your purchase.');
    navigate('/');
  };

  const animatedBg = keyframes`
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  `;

  const fadeInSlide = keyframes`
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  `;

  return (
    <>
      <Header />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '2rem',
          backgroundColor: '#f4f4f4',
          minHeight: '100vh',
        }}
      >
        
        <Box
          sx={{
            width: '100%',
            maxWidth: '1200px',
            backgroundColor: '#fff',
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            padding: '2rem',
            marginBottom: '2rem',
            marginTop: '3rem',
            background: 'linear-gradient(270deg, #ff7e5f, #feb47b, #6200ea, #3700b3)',
            backgroundSize: '400% 400%',
            animation: `${animatedBg} 10s ease infinite`,
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              color: '#fff',
              textAlign: 'center',
              background: 'linear-gradient(90deg, #fff, #fff)',
              color: 'transparent',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              fontSize: '2.5rem',
              textShadow: '1px 1px 4px rgba(0, 0, 0, 0.2)',
              animation: `${fadeInSlide} 1.5s ease-out`,
            }}
          >
            Order Summary
          </Typography>

          <List sx={{ width: '100%', maxHeight: '200px', overflowY: 'auto', marginBottom: '1rem' }}>
            {cartItems.map((item, index) => (
                <Box
                key={index}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '8px',
                    marginBottom: '1rem',
                    padding: '1rem',
                    backgroundColor: '#f9f9f9',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s ease-in-out',
                }}
                >
                <CardMedia
                    component="img"
                    image={item.image || b2bSoftwareImage} 
                    alt={item.title}
                    sx={{
                    height: 60,
                    width: 60,
                    borderRadius: '8px',
                    objectFit: 'cover',
                    marginRight: '1rem',
                    }}
                />
                <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333' }}>
                    {item.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#555' }}>
                    ₹{item.price}
                    </Typography>
                </Box>
                </Box>
            ))}
            </List>

        </Box>

        {/* Shipping Details and Payment Summary */}
        <Grid container spacing={3} sx={{ width: '100%', maxWidth: '1200px' }}>
          {/* Shipping Details */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: '#fff',
                padding: '1.5rem',
                borderRadius: '8px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333', textAlign: 'center', }}>
                Full Address Details
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <TextField
                  label="Full Name"
                  name="name"
                  value={shippingDetails.name}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
                <TextField
                  label="Email"
                  name="email"
                  value={shippingDetails.email}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
                <TextField
                  label="Full Address"
                  name="address"
                  value={shippingDetails.address}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
                <TextField
                  label="Contact Number"
                  name="contact"
                  value={shippingDetails.contact}
                  onChange={handleInputChange}
                  type="number"
                  fullWidth
                  required
                />
              </Box>
            </Box>
          </Grid>

          {/* Payment Summary */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: '#fff',
                padding: '1.5rem',
                borderRadius: '8px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333', textAlign: 'center', }}>
                Payment Summary
              </Typography>
              <Divider sx={{ marginY: '1rem' }} />
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333' }}>
                Total Price: ₹{totalPrice.toFixed(2)}
              </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333' }}>
                GST (18%): ₹{gst.toFixed(2)}
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#6200ea', marginTop: '0.5rem' }}>
                Final Price: ₹{(totalPrice + gst).toFixed(2)}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: '#6200ea',
                  '&:hover': {
                    backgroundColor: '#3700b3',
                  },
                  padding: '0.8rem 2rem',
                  fontSize: '1rem',
                  marginTop: '2rem',
                }}
                onClick={handleConfirmPurchase}
              >
                Pay Now
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* Payment Confirmation Dialog */}
        <Dialog open={isPaymentDialogOpen} onClose={() => setPaymentDialogOpen(false)}>
          <DialogTitle>Confirm Payment</DialogTitle>
          <DialogContent>
            <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
              You are about to pay ₹{(totalPrice + gst).toFixed(2)}. Proceed?
            </Typography>
            <Button variant="contained" color="primary" onClick={handlePayment}>
              Confirm and Pay
            </Button>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default Purchase;
