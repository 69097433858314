import React, { useState } from "react";
import { Container, Grid, TextField, Typography, Box, Button, Card, CardContent } from "@mui/material";
import { useFormik } from "formik";
import axios from "axios";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Email, Phone } from "@mui/icons-material";

const ContactUs = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (values) => {
    try {
      const scriptURL = "https://script.google.com/macros/s/AKfycbyzTI3c8OSU3kIqnH7dGfAAtarsm0f_BA-86Cf778-nIqL1g0Ju6-ZENrK900JbIttAFw/exec";
      console.log("Submitting values:", values);

      await axios.post(scriptURL, new URLSearchParams(values), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("There was an issue submitting the form. Please try again.");
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: ''
    },
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values);
      resetForm();
    }
  });

  return (
    <section>
      <Container maxWidth="lg">
        <Box textAlign="center" my={5}>
          <Typography variant="h6" component="p" sx={{ color: "#f05a22", fontSize: "1.2rem", mb: 1 }}>
            <span>Contact</span>
          </Typography>
          <Typography variant="h4" fontWeight="bold" sx={{ fontSize: { xs: "2rem", md: "2.5rem" }, color: "#333" }}>
            Contact Us
          </Typography>
        </Box>

        <Grid container spacing={4} alignItems="stretch">
          <Grid item xs={12} md={5}>
            <Card 
              sx={{ 
                padding: "20px", 
                backgroundColor: "#f3f4f6", 
                boxShadow: "0px 12px 30px -12px rgba(0,0,0,0.3)", 
                height: "100%",
                borderRadius: "15px",
              }}
            >
              <CardContent>
                <Typography variant="h6" sx={{ color: "#f05a22",fontWeight:"bold", textAlign: "center", mb: 2 }}>
                  Contact Information
                </Typography>
                <Typography variant="h6" sx={{ color: "#1A7BB2", textAlign: "center", mb: 2 }}>
                Deeva Payon Global Pvt Ltd
                </Typography>
                <Box textAlign="center" sx={{ mb: 3, lineHeight: "1.6" }}>
                <Typography variant="body2" fontWeight="bold" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <LocationOnOutlinedIcon sx={{ mr: 1, color: 'red' }} />
                  Hans Bhawan,Mahal,Rana Sanga Marg,near Akshaya Patra Yojna, Sector 26, Jagatpura, Jaipur, Shri Kishanpura, Rajasthan 302017
                </Typography>
                </Box>
                <Box textAlign="center" sx={{ mb: 3, lineHeight: 1.6 }}>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    color="text.secondary"
                    sx={{ display: 'flex',  mb: 1 }}
                  >
                    <Email sx={{ mr: 2, color: 'red' }} />
                       deevapayon@gmail.com
                  </Typography>
                  {/* <Typography
                    variant="body2"
                    fontWeight="bold"
                    color="text.secondary"
                    sx={{ display: 'flex',mb:2, }}
                  >
                    info@deevapayon.com
                  </Typography> */}
                </Box>

                <Box textAlign="center" sx={{ lineHeight: 1.6, mb: 3 }}>
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="text.secondary"
                        sx={{ display: 'flex' }}
                      >
                        <Phone sx={{ mr: 1, color: 'red' }} />
                        +91 9785909785
                      </Typography>
                    </Box>

              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={7}>
            <Card
              sx={{
                padding: "30px",
                backgroundColor: "#ffffff",
                boxShadow: "0px 12px 30px -12px rgba(0,0,0,0.35)",
                borderRadius: "15px",
                height: "100%",
              }}
            >
              {submitted ? (
                <Box textAlign="center" my={3}>
                  <Typography variant="h6" sx={{ color: "#28a745" }}>
                    Thank you! Your message has been sent.
                  </Typography>
                </Box>
              ) : (
                <form onSubmit={formik.handleSubmit}>
                  <Box textAlign="center" mb={3}>
                    <Typography variant="h6" sx={{ color: "#f05a22" }}>
                      Get In Touch
                    </Typography>
                    <Typography variant="h5" fontWeight="bold" sx={{ color: "#333" }}>
                      Send Your Message
                    </Typography>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="name"
                        name="name"
                        label="Your Name *"
                        variant="outlined"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        name="email"
                        label="Email *"
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="phone"
                        name="phone"
                        label="Phone"
                        variant="outlined"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="subject"
                        name="subject"
                        label="Subject"
                        variant="outlined"
                        value={formik.values.subject}
                        onChange={formik.handleChange}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        id="message"
                        name="message"
                        label="Write Message ..."
                        variant="outlined"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                          backgroundColor: "#f05a22",
                          color: "#fff",
                          padding: "12px 24px",
                          fontSize: "1rem",
                          borderRadius: "10px",
                          "&:hover": {
                            backgroundColor: "#e14d1f",
                          },
                        }}
                      >
                        Send Message
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default ContactUs;
