// src/Services/SalesforceServices.js
import React from 'react';
import { Box, Container, Typography, Grid, Button, Card, CardContent } from '@mui/material';
import { Cloud, IntegrationInstructions, Security, SupportAgent } from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import salesforceImage from '../assest/Salesforce Services.jpg';

const SalesforceServices = () => {
  return (
    <>
      <Header />
      <Box sx={{ backgroundColor: '#f9f9f9', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          {/* Hero Section */}
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', gap: 4, marginBottom: '3rem' }}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#1976d2', marginBottom: 2 }}>
                Salesforce Integration Services
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                At Deeva Payon, we offer comprehensive Salesforce solutions to optimize your business operations, enhance customer engagement, and drive productivity through tailored CRM strategies.
              </Typography>
              <Button variant="contained" color="secondary" size="large" sx={{ marginTop: 3 }} href="/contact">
                Start Your Salesforce Journey
              </Button>
            </Box>
            <Box sx={{ flex: 1 }}>
              <img src={salesforceImage} alt="Salesforce Services" style={{ width: '100%', borderRadius: '10px' }} />
            </Box>
          </Box>

          {/* Services Offered */}
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2', textAlign: 'center', marginBottom: '2rem' }}>
            Our Salesforce Services
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Cloud fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Salesforce Cloud Solutions</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    Customized cloud solutions to streamline operations and improve customer engagement through efficient Salesforce platforms.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IntegrationInstructions fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>System Integration</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    Seamless integration of Salesforce with your existing systems to enhance workflow efficiency and data synchronization.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Security fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Data Security and Compliance</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    Robust security measures ensuring that your Salesforce implementation adheres to the highest standards of data privacy and compliance.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <SupportAgent fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Ongoing Support and Training</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    Expert support and training to ensure your team maximizes the benefits of Salesforce and stays up-to-date with platform updates.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Why Choose Us */}
          <Box sx={{ marginTop: '4rem', textAlign: 'center' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
              Why Choose Deeva Payon for Salesforce Services?
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph sx={{ marginTop: '1rem', marginBottom: '3rem' }}>
              Our Salesforce expertise ensures a seamless integration with your business processes, providing you with powerful insights and tools to elevate customer relationships and operational efficiency.
            </Typography>
            <Button variant="contained" color="secondary" size="large" href="/contact">
              Unlock Your Business Potential
            </Button>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default SalesforceServices;
