import React from 'react';
import { Box, Typography, Grid, Button, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import Header from '../components/Header';
import Footer from '../components/Footer';

// Importing assets for each API service
import aepsImg from '../assest/aeps-icon.png';
import bbpsImg from '../assest/bbps-icon.png';
import dmtImg from '../assest/dmt-api.png';
import upiImg from '../assest/upi-api.png';
import payoutImg from '../assest/payout-api.png';
import pancardImg from '../assest/pancard-icon.png';
import whatsappImg from '../assest/whatsapp-api.png';
import aadhaarImg from '../assest/aadhar-verification-api.png';
import rechargeImg from '../assest/rechagreapi.webp';
import APIIMG1 from '../assest/apiimg1.png'

const services = [
  { id: 'aeps', title: 'AEPS API', image: aepsImg, description: 'AEPS is developed by NPCI (National Payment Corporation of India). This system allows individuals to perform financial transactions using their Aadhaar card and biometric authentication.' },
  { id: 'bbps', title: 'BBPS API', image: bbpsImg, description: 'BBPS API is designed to revolutionize the way businesses manage and process bill payments.' },
  { id: 'dmt', title: 'DMT API', image: dmtImg, description: 'Our DMT API provides seamless money transfer services to any bank account in India.' },
  { id: 'upi', title: 'UPI Payment API', image: upiImg, description: 'The UPI Payment API allows businesses to accept and manage payments through Unified Payments Interface.' },
  { id: 'payout', title: 'Payout API', image: payoutImg, description: 'The Payout API facilitates bulk disbursements and vendor payments with ease.' },
  { id: 'pancard', title: 'Pancard API', image: pancardImg, description: 'Our Pancard API enables instant PAN verification services for secure transactions.' },
  { id: 'whatsapp', title: 'Whatsapp API', image: whatsappImg, description: 'Use our Whatsapp API for direct communication and customer engagement.' },
  { id: 'aadhaar', title: 'Aadhaar Verification API', image: aadhaarImg, description: 'Aadhaar Verification API provides biometric-based authentication for secure transactions.' },
  { id: 'recharge', title: 'Recharge API', image: rechargeImg, description: 'The Recharge API enables seamless recharging of mobile, DTH, and data services.' },
];

const ApiServices = () => {
  const scrollToService = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Header />

      {/* Hero Section */}
      <Box 
        sx={{
          width: '100%',
          backgroundImage: `url(${APIIMG1})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          py: 8,
          color: '#fff',
          textAlign: 'center',
          pt: '8rem', // Add padding-top
          pb: 8,  // Optional: Keep bottom padding for spacing consistency
        }}
      >
        <Typography variant="h2" gutterBottom>
          API Integration Made Easy
        </Typography>
        <Typography variant="h6" paragraph>
          Connect your business with the latest technologies using our wide range of APIs.
        </Typography>
        <Button variant="contained" color="secondary" size="large">
          Get Started
        </Button>
      </Box>

      {/* Main Content */}
      <Box id="services-section" sx={{ paddingTop: '4rem', paddingBottom: '4rem', backgroundColor: '#f9f9f9' }}>
        
        {/* Section Heading */}
        <Box textAlign="center" mb={4}>
          <Typography variant="h4" gutterBottom>
            Boost Your Financial Operations with Deeva Payon API Services
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            At Deeva Payon, we deliver best-in-class APIs that meet your unique business needs.
          </Typography>
        </Box>

        {/* Service Icons Section */}
<Box sx={{ px: 5 }}> {/* Add horizontal padding to the whole section */}
  <Grid container spacing={3} justifyContent="center">
    {services.map((service) => (
      <Grid item xs={6} sm={4} md={3} key={service.id}>
        <Paper
          onClick={() => scrollToService(`${service.id}-section`)}
          sx={{
            p: 2,
            textAlign: 'center',
            cursor: 'pointer',
            '&:hover': { boxShadow: 6 },
            borderRadius: 3,
            transition: 'box-shadow 0.3s ease',
          }}
        >
          <img src={service.image} alt={`${service.title}`} width="50%" />
          <Typography variant="subtitle1" mt={1}>
            {service.title}
          </Typography>
        </Paper>
      </Grid>
    ))}
  </Grid>
</Box>

        {/* API Service Details */}
<Box mt={8} sx={{ backgroundColor: '#f3f4f6', padding: '20px', borderRadius: '10px' }}>
  {services.map((service, index) => (
    <Box
      id={`${service.id}-section`}
      key={service.id}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: index % 2 === 0 ? 'row' : 'row-reverse' },
        alignItems: 'center',
        justifyContent: 'space-between',
        py: 6,
        px: 2,
        backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9f9f9',
        border: '2px solid #e0e0e0', // Subtle border
        borderRadius: '10px', // Rounded corners
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
        transition: 'background-color 0.3s ease-in-out',
        marginBottom: '20px', // Space between each service section
        maxWidth: '1200px', // Limit width for larger screens
        margin: '0 auto', // Center the section
      }}
    >
      <Box flex={1} textAlign="center" p={2}>
        <img src={service.image} alt={`${service.title}`} style={{ maxWidth: '70%', height: 'auto', borderRadius: '10px' }} />
      </Box>
      <Box flex={1} p={2}>
        <Typography variant="h5" gutterBottom>
          {service.title}
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          {service.description}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.href = `/api-service/${service.id}`}
          component={motion.div}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          sx={{
            backgroundColor: '#f05a22', // Your accent color
            '&:hover': {
              backgroundColor: '#e14d1f', // Darker shade on hover
            },
          }}
        >
          Read More
        </Button>
      </Box>
    </Box>
  ))}
</Box>


        {/* Call to Action */}
        <Box textAlign="center" sx={{ mt: 4 }}>
          <Button variant="contained" color="primary" size="large">
            Contact Us for API Integration
          </Button>
        </Box>
      </Box>

      <Footer />
    </>
  );
};

export default ApiServices;
