import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Container, IconButton, Drawer, List, ListItem, ListItemText, Box, ListItemButton } from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Updated import
import Logo from '../assest/cropped-DEEVA-LOGO-2.jpg';
import ContactFormPopup from './ContactFormPopup'; // Import the popup form component
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';


const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isContactFormOpen, setIsContactFormOpen] = useState(false); // Popup form state
  const location = useLocation();
  const navigate = useNavigate(); // Hook to update the URL

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleContactFormOpen = () => {
    setIsContactFormOpen(true); // Open form
    navigate("?popup=open"); // Add ?popup=open to URL
  };

  const handleContactFormClose = () => {
    setIsContactFormOpen(false); // Close form
    navigate(location.pathname); // Remove the query parameter, keeping only the path
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('popup') === 'open') {
      setIsContactFormOpen(true); // Open the popup if ?popup=open
    }
  }, [location]);

  const drawer = (
    <Box sx={{ width: 250 }} role="presentation" onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
      <IconButton onClick={handleDrawerToggle} sx={{ marginLeft: 'auto' }}>
        <CloseIcon />
      </IconButton>
      <List>
        <ListItem button component={Link} to="/" key="Home">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/about" key="About Us">
          <ListItemText primary="About Us" />
        </ListItem>
        <ListItem button component={Link} to="/services" key="Services">
          <ListItemText primary="Services" />
        </ListItem>
        <ListItem button component={Link} to="/blog" key="Blog">
          <ListItemText primary="Blog" />
        </ListItem>
        <ListItem button component={Link} to="/career" key="Career">
          <ListItemText primary="Career" />
        </ListItem>
        <ListItem button component={Link} to="/contact" key="Contact">
          <ListItemText primary="Contact" />
        </ListItem>
        <ListItem button>
          <Button variant="contained" color="secondary" fullWidth onClick={handleContactFormOpen}>
            Get In Touch
          </Button>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/cart">
            <Button variant="contained" color="secondary" fullWidth startIcon={<AddShoppingCartIcon />}>
            </Button>
          </ListItemButton>
          </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: '#fff',
          boxShadow: isScrolled ? '0px 2px 4px rgba(0,0,0,0.2)' : 'none',
          transition: '0.3s',
          color: "#000",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Typography variant="h6" component={Link} to="/" sx={{ flexGrow: 1, textDecoration: 'none', color: '#fff' }}>
              <img src={Logo} alt="Logo" style={{ height: '50px', paddingTop: '1rem' }} />
            </Typography>

            <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
              <Button color="inherit" component={Link} to="/" sx={{ '&:hover': { backgroundColor: '#9c27b0', color: "#fff" }, fontSize: '15px' }}>
                Home
              </Button>
              <Button color="inherit" component={Link} to="/about" sx={{ '&:hover': { backgroundColor: '#9c27b0', color: "#fff" }, fontSize: '15px' }}>
                About Us
              </Button>
              <Button color="inherit" component={Link} to="/services" sx={{ '&:hover': { backgroundColor: '#9c27b0', color: "#fff" }, fontSize: '15px' }}>
                Services
              </Button>
              <Button color="inherit" component={Link} to="/blog" sx={{ '&:hover': { backgroundColor: '#9c27b0', color: "#fff" }, fontSize: '15px' }}>
                Blog
              </Button>
              <Button color="inherit" component={Link} to="/career" sx={{ '&:hover': { backgroundColor: '#9c27b0', color: "#fff" }, fontSize: '15px' }}>
                Career
              </Button>
              <Button color="inherit" component={Link} to="/contact" sx={{ '&:hover': { backgroundColor: '#9c27b0', color: "#fff" }, fontSize: '15px' }}>
                Contact
              </Button>
              <Button variant="contained" color="secondary" sx={{ marginLeft: 2 }} onClick={handleContactFormOpen}>
                Get In Touch
              </Button>
              <Button variant="contained" color="secondary" sx={{ marginLeft: 2 }} component={Link} to="/cart">
                <AddShoppingCartIcon />
              </Button>
            </Box>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 250 },
        }}
      >
        {drawer}
      </Drawer>

      <ContactFormPopup open={isContactFormOpen} onClose={handleContactFormClose} />
    </>
  );
};

export default Header;
