// src/pages/Services.js
import React, { useContext, useState } from 'react';
import { Container, Typography, Grid, Card, CardContent } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Servicespage from '../Services/Servicespage';
import { CartContext } from '../CartContext';


const Services = () => {
 
  
  return (
    <>
      <Header />
      <Servicespage/>
      <Footer />
    </>
  );
};

export default Services;
