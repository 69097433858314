// src/Services/MetaAds.js
import React from 'react';
import { Box, Container, Typography, Grid, Button, Card, CardContent } from '@mui/material';
import { Campaign, People, Insights, Visibility } from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import metaAdsImage from '../assest/Meta ADS.png';

const MetaAds = () => {
  return (
    <>
      <Header />
      <Box sx={{ backgroundColor: '#f9f9f9', padding: '8rem 0' }}>
        <Container maxWidth="lg">
          {/* Hero Section */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              gap: 2,
              marginBottom: '2rem',
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2', marginBottom: 1 }}>
                Meta Ads Services
              </Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                Boost your brand's visibility on Facebook and Instagram with Deeva Payon’s Meta Ads solutions. We target your audience precisely, delivering high-engagement ads that resonate with your potential customers.
              </Typography>
              <Button variant="contained" color="secondary" size="medium" sx={{ marginTop: 2 }} href="/contact">
                Start Your Meta Campaign
              </Button>
            </Box>
            <Box sx={{ flex: 1 }}>
              <img src={metaAdsImage} alt="Meta Ads Services" style={{ width: '100%', borderRadius: '8px' }} />
            </Box>
          </Box>

          {/* Services Offered */}
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1976d2', textAlign: 'center', marginBottom: '1.5rem' }}>
            What We Offer
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  transition: '0.3s',
                  '&:hover': { transform: 'translateY(-5px)', boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)' },
                }}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Campaign fontSize="medium" sx={{ color: '#1976d2' }} />
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      Campaign Strategy
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                    We create targeted strategies that align with your goals, ensuring your campaigns reach the right audience.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  transition: '0.3s',
                  '&:hover': { transform: 'translateY(-5px)', boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)' },
                }}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <People fontSize="medium" sx={{ color: '#1976d2' }} />
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      Audience Targeting
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                    Our approach focuses on specific audience demographics and interests to maximize engagement and relevance.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  transition: '0.3s',
                  '&:hover': { transform: 'translateY(-5px)', boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)' },
                }}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Insights fontSize="medium" sx={{ color: '#1976d2' }} />
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      Performance Analytics
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                    We provide in-depth analysis and insights, helping you understand ad effectiveness and ROI.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  transition: '0.3s',
                  '&:hover': { transform: 'translateY(-5px)', boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)' },
                }}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Visibility fontSize="medium" sx={{ color: '#1976d2' }} />
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      Brand Awareness
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                    Enhance your brand's presence on Facebook and Instagram, reaching a broad and targeted audience.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Why Choose Us */}
          <Box sx={{ marginTop: '3rem', textAlign: 'center' }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
              Why Choose Deeva Payon for Meta Ads?
            </Typography>
            <Typography variant="body2" color="textSecondary" paragraph sx={{ marginTop: '0.5rem', marginBottom: '2rem' }}>
              With our expertise in social media advertising, we drive engagement and conversions through data-backed strategies, making sure every campaign delivers results.
            </Typography>
            <Button variant="contained" color="secondary" size="medium" href="/contact">
              Connect with Us Today
            </Button>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default MetaAds;
