import React, { useState } from "react";
import { Container, Grid, TextField, Typography, Box, Button, Paper } from "@mui/material";
import { useFormik } from "formik";
import axios from "axios";

const ContactUs = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (values) => {
    try {
      const scriptURL = "https://script.google.com/macros/s/AKfycbyzTI3c8OSU3kIqnH7dGfAAtarsm0f_BA-86Cf778-nIqL1g0Ju6-ZENrK900JbIttAFw/exec";
      
      // Log values to confirm data
      console.log("Submitting values:", values);

      // Send form data to Google Sheets
      await axios.post(scriptURL, new URLSearchParams(values), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("There was an issue submitting the form. Please try again.");
    }
  };

  // Formik for form handling
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: ''
    },
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values);
      resetForm();
    }
  });

  return (
    <section>
      <Container maxWidth="lg">
        <Box textAlign="center" my={5}>
          <Typography variant="h6" component="p" sx={{ color: "#f05a22" }}>
            <span>Contact</span>
          </Typography>
          <Typography variant="h4" fontWeight="bold">
            Contact Us
          </Typography>
        </Box>

        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <iframe
              src="https://www.google.com/maps/d/embed?mid=1h2_T7Z1sRNCewLZvbC32fE2Q9tDWGoo&ehbc=2E312F"
              width="100%"
              height="450"
              style={{ border: 0, borderRadius: "10px" }}
              allowFullScreen=""
              loading="lazy"
              title="Google Map"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper
              elevation={5}
              sx={{
                padding: "30px",
                backgroundColor: "#f9f9f9",
                boxShadow: "0px 10px 25px -10px rgba(0,0,0,0.45)",
                borderRadius: "10px",
              }}
            >
              {submitted ? (
                <Box textAlign="center" my={3}>
                  <Typography variant="h6" sx={{ color: "#28a745" }}>
                    Thank you! Your message has been sent.
                  </Typography>
                </Box>
              ) : (
                <form onSubmit={formik.handleSubmit}>
                  <Box textAlign="center" mb={3}>
                    <Typography variant="h6" sx={{ color: "#f05a22" }}>
                      Get In Touch
                    </Typography>
                    <Typography variant="h5" fontWeight="bold">
                      Send Your Message
                    </Typography>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="name"
                        name="name"
                        label="Your Name *"
                        variant="outlined"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        name="email"
                        label="Email *"
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="phone"
                        name="phone"
                        label="Phone"
                        variant="outlined"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="subject"
                        name="subject"
                        label="Subject"
                        variant="outlined"
                        value={formik.values.subject}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        id="message"
                        name="message"
                        label="Write Message ..."
                        variant="outlined"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                          backgroundColor: "#f05a22",
                          color: "#fff",
                          padding: "10px 20px",
                          "&:hover": {
                            backgroundColor: "#e14d1f",
                          },
                        }}
                      >
                        Send Message
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default ContactUs;
