// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import B2BSoftware from './Services/B2BSoftware';
import AndroidAppDevelopment from './Services/androidapplicationdevelopment';
import WebsiteDevelopment from './Services/websitedevelopment';
import GraphicDesign from './Services/graphicdesign';
import FintechSoftwareDevelopment from './Services/fintechsoftwaredevelopment';
import SoftwareDevelopment from './Services/softwaredevelopment';
import B2CSoftwere from './Services/B2CSoftwere';
import Blog from './pages/Blog';
import Careers from './pages/Career';
import DigitalMarketing from './Services/DigitalMarketing';
import AboutUs from './pages/AboutNew';
import ApiServices from './Services/api-services';
import CloudSolutions from './Services/cloudsolutions';
import CRMSolutions from './Services/crmsolutions';
import ECommerceServices from './Services/e-commerceservices';
import SalesforceServices from './Services/salesforceservices';
import SocialMediaMarketing from './Services/SocialMediaMarketing';
import SEO from './Services/SEO';
import GoogleAds from './Services/GoogleAds';
import MetaAds from './Services/MetaAds';
import FlutterDevelopment from './Services/FlutterDevelopment';
import IOSDevelopment from './Services/IOSDevelopment';
import WordPressDevelopment from './Services/WordPressDevelopment';
import StaticDynamicWebDevelopment from './Services/StaticDynamicWebDevelopment';
import BlogWebsiteDevelopment from './Services/BlogWebsiteDevelopment';
import FAQ from './pages/FAQ';
import ScrollToTopBotton from './components/ScrollToTop/ScrollToTop'

import WhatsApp from './WhatsApp/WhatsApp';
import AepsApiPage from './api-service/aepsapi';
import BBPSApi from './api-service/BBPSApi';
import DmtApi from './api-service/DmtApi';
import UpiPaymentApi from './api-service/UipiPaymentApi';
import PayoutApi from './api-service/PayoutApi';
import PancardApi from './api-service/PancardApi';
import WhatsappApi from './api-service/WhatsappApi';
import AadhaarVerificationApi from './api-service/AadhaarVerificationApi';
import RechargeApi from './api-service/RechargeApi';
import ScrollToTop from './ScrollTop';
import PrivacyPolicy from './Services/PrivacyPolicy';
import Refund from './Services/Refund';
import TermCondition from './Services/TermCondition';
import Cart from './pages/Cart';
import Purchase from './pages/Purchase';

import BlogPostFutureFintech from './blog/BlogPostFutureFintech';
import MicroATMblog from './blog/MicroATMblog';
import SDPracticesblog from './blog/SDPracticesblog';

function App() {
  return (
    <Router>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/career" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services/b2bsoftware" element={<B2BSoftware/>} />
          <Route path="/services/andriodapp" element={<AndroidAppDevelopment/>} />
          <Route path="/services/websitedevelopment" element={<WebsiteDevelopment/>} />
          <Route path="/services/graphic-design" element={<GraphicDesign/>} />
          <Route path="/services/fintechsoftweredevelopment" element={<FintechSoftwareDevelopment/>}/>
          <Route path="/services/B2CSoftwere" element={<B2CSoftwere/>} />
          <Route path="/services/softwaredevelopment" element={<SoftwareDevelopment/>} />
          <Route path="/services/digitalmarketing" element={<DigitalMarketing />} />
          <Route path="/aboutnew" element={<AboutUs />} />
          <Route path="/services/apiservices" element={<ApiServices />} />
          <Route path="/services/cloudsolutions" element={<CloudSolutions />} />
          <Route path="/services/crmsolutions" element={<CRMSolutions />} />
          <Route path="/services/E-CommerceServices" element={<ECommerceServices />} />
          <Route path="/services/SalesforceServices" element={<SalesforceServices />} />
          <Route path="/scrollToTopBotton" element= {<ScrollToTopBotton/>}/>
          <Route path="/Services/SocialMediaMarketing" element={<SocialMediaMarketing />} />
          <Route path="/Services/seo" element={<SEO />} />
          <Route path="/services/googleads" element={<GoogleAds />} />
          <Route path="/services/metaads" element={<MetaAds />} />
          <Route path="/services/FlutterDevelopment" element={<FlutterDevelopment/>} />
          <Route path="/services/IOSDevelopment" element={<IOSDevelopment />} />
          <Route path="/services/WordPressDevelopment" element={<WordPressDevelopment />} />
          <Route path="/services/StaticDynamicWebDevelopment" element={<StaticDynamicWebDevelopment />} />
          <Route path="/services/BlogWebsiteDevelopment" element={<BlogWebsiteDevelopment />} />
          <Route path="/pages/faq" element={<FAQ/>} />
          <Route path="/api-service/aeps" element={<AepsApiPage />} />
          <Route path="/api-service/bbps" element={<BBPSApi/>} />
          <Route path="/api-service/dmt" element={<DmtApi />} />
          <Route path="/api-service/upi" element={<UpiPaymentApi />} />
          <Route path="/api-service/payout" element={<PayoutApi />} />
          <Route path="/api-service/pancard" element={<PancardApi />} />
          <Route path="/api-service/whatsapp" element={<WhatsappApi />} />
          <Route path="/api-service/aadhaar" element={<AadhaarVerificationApi />} />
          <Route path="/api-service/recharge" element={<RechargeApi />} />
          {/* Blog Routes */}
          <Route path="/blog/future-of-fintech-2025" element={<BlogPostFutureFintech/>} />
          <Route path="/blog/MicroATMblog" element={<MicroATMblog/>} />
          <Route path="/blog/SDPracticesblog" element={<SDPracticesblog/>} />

          <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>}/>
          <Route path="/RefundPolicy" element={<Refund/>}/>
          <Route path="/Term&Condition" element={<TermCondition/>}/>

          <Route path="/cart" element={<Cart/>} />
          <Route path="/purchase" element={<Purchase/>} />


        </Routes>
        <WhatsApp/>
        <ScrollToTopBotton/>
        
        <ScrollToTop/>
      </Box>
    </Router>
  );
}

export default App;