import React from 'react';
import { Box, Typography, Container, Grid, Button, Paper } from '@mui/material';
import Header from '../components/Header'; // Adjust path based on file structure
import Footer from '../components/Footer';
import { motion } from 'framer-motion';
import Cloudimg from '../assest/cloud-solutions.png'
const CloudSolutions = () => {
  return (
    <>
      <Header />

      {/* Main Content Section */}
      <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
        
        {/* Banner Section */}
        <Box
          sx={{
            textAlign: 'center',
            py: 5,
            px: 2,
            // backgroundImage: {Cloudimg}, // Replace with a cloud-themed image
            backgroundColor: 'blue',
            backgroundSize: 'cover',
            color: '#fff',
            borderRadius: '8px',
          }}
        >
          <Typography variant="h3" fontWeight="bold" gutterBottom>
            Cloud Solutions
          </Typography>
          <Typography variant="h6">
            Flexible, Scalable, and Secure Cloud Solutions to Drive Your Business Forward
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: 3 }}
            onClick={() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}
          >
            Start Your Journey
          </Button>
        </Box>

        {/* Overview Section */}
        <Box mt={6}>
          <Typography variant="h4" fontWeight="bold" align="center" gutterBottom>
            Why Choose Our Cloud Solutions?
          </Typography>
          <Typography variant="body1" align="center" color="text.secondary" sx={{ mb: 4, maxWidth: 800, mx: 'auto' }}>
            Discover the benefits of cloud technology, including enhanced scalability, improved security, and reduced costs.
          </Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <img src={Cloudimg} alt="Cloud Solutions" style={{ width: '100%', borderRadius: '8px' }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {['High Security', 'Resource Flexibility', 'Collaboration', 'Data Access'].map((benefit, index) => (
                  <li key={index} style={{ marginBottom: '15px' }}>
                    <Typography variant="h6" fontWeight="bold">
                      {benefit}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Details on how {benefit} benefits our clients with efficient and secure cloud solutions.
                    </Typography>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        {/* Features Section */}
        <Box mt={8} sx={{ textAlign: 'center' }}>
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            Key Features
          </Typography>
          <Grid container spacing={4}>
            {['Data Security', 'Scalability', '24/7 Support', 'Cost Efficiency', 'Customizability'].map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Paper elevation={4} sx={{ p: 3, borderRadius: '8px', height: '100%' }}>
                  <Typography variant="h6" fontWeight="bold">
                    {feature}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    Detailed description of {feature} showcasing its importance in cloud solutions.
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* CTA Section */}
        <Box mt={8} textAlign="center" sx={{ py: 5, backgroundColor: '#4a90e2', color: '#fff', borderRadius: '8px' }}>
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            Ready to Transform Your Business?
          </Typography>
          <Typography variant="body1" sx={{ maxWidth: '700px', mx: 'auto', mb: 3 }}>
            Connect with us to explore how our Cloud Solutions can drive your business forward.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.href = '/contact'}
          >
            Contact Us
          </Button>
        </Box>

      </Container>

      <Footer />
    </>
  );
};

export default CloudSolutions;
