// HowWeWork.js
import React from 'react';
import { Box, Typography, Grid, Card, CardContent, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faChartSimple, faPencil, faRocket, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';

const steps = [
    { number: "", title: "Requirement Analysis", icon: faChartSimple },
    { number: "", title: "UI/UX Designing", icon: faPencil },
    { number: "", title: "Development", icon: faLaptopCode },
    { number: "", title: "Quality Assurance", icon: faChartSimple },
    { number: "", title: "Site Launch", icon: faRocket },
    { number: "", title: "Support & Maintenance", icon: faScrewdriverWrench },
];

const HowWeWork = () => {
    return (
        <Box 
            sx={{ 
                textAlign: 'center', 
                py: 5, 
                backgroundColor: '#f9f9f9' 
            }}
        >
            <Typography 
                variant="h4" 
                sx={{
                    mb: 3,
                    color: '#fcfcfc',
                    background: 'linear-gradient(135deg, rgba(6, 9, 97, 0.9), rgba(32, 118, 247, 0.8))',
                    display: 'inline-block',
                    px: 4,
                    py: 1,
                    borderRadius: '30px',
                }}
            >
                How We Work
            </Typography>
            <Grid container spacing={3} justifyContent="center">
                {steps.map((step, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card 
                            sx={{
                                maxWidth: 300,
                                mx: 'auto',
                                textAlign: 'center',
                                boxShadow: 3,
                                border: '2px solid rgb(35, 140, 221)',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    transform: 'translateY(-10px)',
                                    boxShadow: 10,
                                },
                            }}
                        >
                            <CardContent 
                                sx={{
                                    transition: 'background 0.3s ease',
                                    '&:hover': {
                                        background: 'linear-gradient(135deg, rgba(6, 9, 97, 0.9), rgba(32, 118, 247, 0.8))', // Apply gradient on hover
                                        color: 'white', // Change text color for better visibility
                                    },
                                }}
                            >
                                <IconButton 
                                    sx={{
                                        backgroundColor: '#001f3f',
                                        color: 'white',
                                        mb: 2,
                                        width: 56,
                                        height: 56,
                                        '& .MuiSvgIcon-root': { fontSize: 28 },
                                    }}
                                >
                                    <FontAwesomeIcon icon={step.icon} />
                                </IconButton>
                                <Typography variant="h6" color="textSecondary">
                                    {step.number}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                                    {step.title}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default HowWeWork;
