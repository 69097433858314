// src/Services/DmtApi.js
import React from 'react';
import { Box, Container, Typography, Grid, Button, Card, CardContent } from '@mui/material';
import { Send, AccountBalance, Lock, Support } from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import dmtImage from '../assest/DMT API.png'; // Replace with the actual image path

const DmtApi = () => {
  return (
    <>
      <Header />
      <Box
        sx={{
          backgroundColor: '#e0f7fa',
          padding: '7rem 0',
        }}
      >
        <Container maxWidth="lg">
          {/* Hero Section */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              gap: 4,
              marginBottom: '3rem',
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#00796b', marginBottom: 2 }}>
                DMT API
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                The DMT (Domestic Money Transfer) API facilitates secure and efficient money transfers across various banking networks. It empowers businesses to offer seamless remittance services, ensuring that funds reach their destination quickly and reliably.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                sx={{ marginTop: 3 }}
                href="/contact"
              >
                Discover More
              </Button>
            </Box>
            <Box sx={{ flex: 1 }}>
              <img
                src={dmtImage}
                alt="DMT API"
                style={{ width: '100%', borderRadius: '10px' }}
              />
            </Box>
          </Box>

          {/* Key Features */}
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bold', color: '#00796b', textAlign: 'center', marginBottom: '2rem' }}
          >
            Key Features of DMT API
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  transition: '0.3s',
                  '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' },
                }}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Send fontSize="large" sx={{ color: '#00796b' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Fast and Reliable Transfers
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    Experience rapid money transfers with real-time processing, ensuring that funds are available instantly.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  transition: '0.3s',
                  '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' },
                }}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <AccountBalance fontSize="large" sx={{ color: '#00796b' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Multi-Banking Integration
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    Our API supports seamless integration with multiple banks, allowing for easy transfers between various financial institutions.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  transition: '0.3s',
                  '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' },
                }}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Lock fontSize="large" sx={{ color: '#00796b' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Enhanced Security Features
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    The DMT API implements advanced security protocols to protect transactions and safeguard user data, ensuring compliance with industry standards.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  transition: '0.3s',
                  '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' },
                }}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Support fontSize="large" sx={{ color: '#00796b' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      24/7 Customer Support
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    Our dedicated support team is here to assist you around the clock, ensuring smooth operations and prompt resolution of any issues.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Why Choose Us */}
          <Box sx={{ marginTop: '4rem', textAlign: 'center' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#00796b' }}>
              Why Choose Deeva Payon for DMT API Integration?
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph sx={{ marginTop: '1rem', marginBottom: '3rem' }}>
              At Deeva Payon, we are committed to delivering innovative solutions that empower your business. Our DMT API provides a flexible and scalable framework to support your money transfer needs in a rapidly changing market.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              href="/contact"
            >
              Start Your DMT Journey
            </Button>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default DmtApi;
