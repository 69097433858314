// src/components/Services.js
import React, { useContext, useState,  } from 'react';
import { Grid, Card, CardContent, Typography, Box, Button, Snackbar, Alert, Icon  } from '@mui/material';
import { HomeRepairService, Security, Business, Cloud, PhoneAndroid, Devices, ShoppingCart, DesignServices, AccountBalance, Payment, Storage, Code, MacroOffRounded, MilitaryTech, TableChart, Api } from '@mui/icons-material';
import ServiceBackground from '../assest/services.jpg'; 
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { CartContext } from '../CartContext';
import b2bSoftwareImage from '../assest/B2B Software Solutions.png'; 
import AndroidAppDevelopment from '../assest/AndroidAppDevelopment.png'; 
import websitedevelopment from '../assest/websitedevelopment.png'; 
import graphicdesign from '../assest/graphic-design.png'; 
import fintechsoftweredevelopment from '../assest/fintechsoftweredevelopment.webp'; 
import cloudsolutions from '../assest/cloud-solutions.png'; 
import CRMSolutions from '../assest/CRM Solutions.jpg'; 
import ecomimg1 from '../assest/ecom-img1.jpg'; 
import softwaredevelopment from '../assest/software-development.jpg'; 
import digital_marketing from '../assest/digital_marketing.avif'; 
import apiimg1 from '../assest/apiimg1.png'; 
import SalesforceServices from '../assest/Salesforce Services.jpg'; 


const services = [
  {
    icon: <Business fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'B2B Software Solutions',
    description: 'Robust software solutions to enhance your business processes.',
    link: '/services/b2bsoftware',
    price: 1500,
    image: b2bSoftwareImage,
  },
  {
    icon: <PhoneAndroid fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'Android Application Development',
    description: 'Custom Android apps to meet your business and customer needs.',
    link: '/services/andriodapp',
    price: 1000,
    image: AndroidAppDevelopment,
  },
  {
    icon: <Devices fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'Website Development',
    description: 'Stunning websites built with the latest technologies for optimal performance.',
    link: '/services/websitedevelopment',
    price: 12500,
    image: websitedevelopment,
  },
  {
    icon: <DesignServices fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'Graphic Design',
    description: 'Creative designs to captivate your audience and boost engagement.',
    link: '/services/graphic-design',
    price: 500,
    image: graphicdesign,
  },
  {
    icon: <AccountBalance fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'Fintech Software Development',
    description: 'Innovative financial technology solutions for the modern enterprise.',
    link: '/services/fintechsoftweredevelopment',
    price: 20000,
    image: fintechsoftweredevelopment,
  },

  {
    icon: <Cloud fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'Cloud Solutions',
    description: 'Scalable and secure cloud computing services for your business needs.',
    link: '/services/cloudsolutions',
    price: 5000,
    image: cloudsolutions,
  },
  {
    icon: <Storage fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'CRM Solutions',
    description: 'Customer Relationship Management systems to streamline your operations.',
    link: '/services/crmsolutions',
    price: 5000,
    image: CRMSolutions,
  },
  {
    icon: <ShoppingCart fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'E-Commerce Services',
    description: 'Complete e-commerce solutions to take your online store to the next level.',
    link: '/services/E-CommerceServices',
    price: 2500,
    image: ecomimg1,
  },
  {
    icon: <Code fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'Salesforce Services',
    description: 'Tailored Salesforce development to optimize your customer interactions.',
    link: '/services/SalesforceServices',
    price: 1000,
    image: SalesforceServices,
  },
  {
    icon: <Code fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'Software Development',
    description: 'End-to-end software development services for businesses of all sizes.',
    link: '/services/softwaredevelopment',
    price: 1500,
    image: softwaredevelopment,
  },
  {
    icon: <TableChart fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'Digital Marketing',
    description: 'A New Era of Digital Marketing that cover all your need, PPC, CPC, SEO, SMM.',
    link: '/services/digitalmarketing',
    price: 500,
    image: digital_marketing,
  },
  {
    icon: <Api fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'API Services',
    description: 'API services designed to help businesses integrate innovative solutions seamlessly into their platforms.',
    link: '/services/apiservices',
    price: 1000,
    image: apiimg1,
  },
];

const Services = () => {

  const { addToCart } = useContext(CartContext);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleAddToCart = (service) => {
    addToCart(service);
    setShowSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };


  

  return (
    <Box
      id="services-section"
      sx={{
        padding: '7rem 0',
        backgroundImage: `url(${ServiceBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: '#f5f5f5',
        width: '100%',
      }}
    >
      <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#fff' }}>
        Our Services
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary" paragraph sx={{ color: '#ddd' }}>
        We offer a wide range of innovative services to meet all your business needs.
      </Typography>
      <Box sx={{ width: '90%', margin: '0 auto' }}>
        <Grid container spacing={4} justifyContent="center">
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  transition: '0.3s',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)',
                  },
                  padding: '1.5rem',
                  backgroundColor: '#ffffffcc', // Transparent background
                  borderRadius: '12px',
                  textAlign: 'center',
                  backdropFilter: 'blur(10px)', // Adds glassmorphism effect
                }}
              >
                <CardContent>
                  <Box sx={{ mb: 2 }}>
                    {service.icon}
                  </Box>
                  <Typography variant="h6" sx={{ margin: '1rem 0', fontWeight: 'bold', color: '#1976d2' }}>
                    {service.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {service.description}
                  </Typography>
                  <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={service.link}
                      startIcon={<InfoIcon />}
                      sx={{ mt: 2, mr: 1 }} 
                    >
                      Learn More
                    </Button>
                    <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleAddToCart(service)}
                    startIcon={<AddShoppingCartIcon />}
                    sx={{ mt: 2 }}
                  >
                    Buy Now
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Added to cart successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Services;

