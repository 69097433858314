import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./OurBanking.css";
import YesBank from '../../../assest/Yes_Bank_logo.svg.png';
import HDFCBank from '../../../assest/hdfc-logo.png';
import IDFCBank from '../../../assest/idfc-logo.jpg';
import Paytm from '../../../assest/paytm-logo.png';
import ICICIBank from '../../../assest/icici-logo.png';
import RBLBank from '../../../assest/rbl-logo.png';

const OurBanking = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  return (
    <div className="block-title text-center my-5">
      <h2>Our Banking Associates</h2>
      <section className="container partner-section">
        <Slider {...settings} className="banking-partner-carousel">
          <div className="item">
            <div className="bank-partner-img">
              <img src={HDFCBank} alt="HDFC Bank" />
            </div>
          </div>
          <div className="item">
            <div className="bank-partner-img">
              <img src={IDFCBank} alt="IDFC Bank" />
            </div>
          </div>
          <div className="item">
            <div className="bank-partner-img">
              <img src={Paytm} alt="Paytm" />
            </div>
          </div>
          <div className="item">
            <div className="bank-partner-img">
              <img src={ICICIBank} alt="ICICI Bank" />
            </div>
          </div>
          <div className="item">
            <div className="bank-partner-img">
              <img src={RBLBank} alt="RBL Bank" />
            </div>
          </div>
          <div className="item">
            <div className="bank-partner-img">
              <img src={YesBank} alt="Yes Bank" />
            </div>
          </div>
        </Slider>
      </section>
      <div className="bg-bank-head mt-5"></div>
    </div>
  );
};

export default OurBanking;
