// ContactPage.js
import React from 'react';
import { Typography, Box } from '@mui/material';

const ContactPage = () => {
  return (
    <Box
      sx={{
        height: '50vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(135deg, #1976d2, #004ba0)', // Background gradient
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Floating Background Animation */}
      <Box
        sx={{
          position: 'absolute',
          top: '-20%',
          left: '-20%',
          width: '140%',
          height: '140%',
          background: 'radial-gradient(circle, rgba(255,255,255,0.1), transparent)',
          animation: 'float 6s ease-in-out infinite',
          '@keyframes float': {
            '0%, 100%': { transform: 'translateY(0px)' },
            '50%': { transform: 'translateY(20px)' },
          },
        }}
      />

      <Typography
        variant="h2"
        component="h1"
        sx={{
          background: 'linear-gradient(90deg, #00e1ff, #00c1d4)',
          backgroundClip: 'text',
          color: 'transparent',
          fontWeight: 'bold',
          letterSpacing: '0.1em',
          textShadow: `
            0px 0px 5px rgba(0, 225, 255, 0.7),
            0px 0px 10px rgba(0, 193, 212, 0.5),
            0px 0px 15px rgba(0, 225, 255, 0.3)
          `,
          position: 'relative',
          zIndex: 1,
          animation: 'glow 3s ease-in-out infinite',
          '@keyframes glow': {
            '0%, 100%': { textShadow: '0px 0px 5px rgba(0, 225, 255, 0.7), 0px 0px 10px rgba(0, 193, 212, 0.5), 0px 0px 15px rgba(0, 225, 255, 0.3)' },
            '50%': { textShadow: '0px 0px 10px rgba(0, 225, 255, 1), 0px 0px 20px rgba(0, 193, 212, 0.7), 0px 0px 30px rgba(0, 225, 255, 0.5)' },
          },
          '&::after': {
            content: '""',
            display: 'block',
            width: '80%',
            height: '6px',
            background: 'linear-gradient(90deg, #00e1ff, #00c1d4)', // Gradient underline
            margin: '0.75rem auto 0',
            borderRadius: '4px',
            transform: 'rotate(-3deg)', // Slanted underline
            animation: 'glowUnderline 3s ease-in-out infinite', // Glow effect for underline
            boxShadow: `
              0px 0px 5px rgba(0, 225, 255, 0.5),
              0px 0px 10px rgba(0, 193, 212, 0.4),
              0px 0px 15px rgba(0, 225, 255, 0.3)
            `,
          },
          '@keyframes glowUnderline': {
            '0%, 100%': { boxShadow: '0px 0px 5px rgba(0, 225, 255, 0.5), 0px 0px 10px rgba(0, 193, 212, 0.4), 0px 0px 15px rgba(0, 225, 255, 0.3)' },
            '50%': { boxShadow: '0px 0px 10px rgba(0, 225, 255, 0.7), 0px 0px 15px rgba(0, 193, 212, 0.6), 0px 0px 20px rgba(0, 225, 255, 0.5)' },
          },
        }}
      >
        CONTACT US
      </Typography>
    </Box>
  );
};

export default ContactPage;
