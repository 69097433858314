import React, { useContext, useState } from 'react';
import { CartContext } from '../CartContext.js';
import { Box, Typography, List, Button, Divider, CardMedia, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import b2bSoftwareImage from '../assest/B2B Software Solutions.png';

const Cart = () => {
  const { cartItems, removeFromCart } = useContext(CartContext);
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar visibility

  const handleBuyNow = () => {
    navigate('/purchase'); // Redirect to a purchase page
  };

  const handleRemoveFromCart = (index) => {
    removeFromCart(index);
    setOpenSnackbar(true); // Show snackbar when an item is removed
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false); // Close snackbar
  };

  return (
    <>
      <Header />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#f4f4f4',
          padding: '2rem',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '1200px',
            backgroundColor: '#fff',
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            padding: '2rem',
            marginTop: '5rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: 'auto',
            minHeight: '60vh',
            overflowY: 'auto',
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              color: '#333',
              textAlign: 'center',
              fontSize: '2rem',
              marginBottom: '2rem',
            }}
          >
            Your Cart
          </Typography>

          {cartItems.length === 0 ? (
            <Typography sx={{ textAlign: 'center', color: '#888', fontSize: '1.5rem' }}>
              Your cart is empty. Start shopping now!
            </Typography>
          ) : (
            <List sx={{ width: '100%' }}>
              {cartItems.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    borderRadius: '12px',
                    marginBottom: '1.5rem',
                    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                    padding: '1.5rem',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.15)',
                      transform: 'scale(1.02)',
                    },
                    '@media (min-width: 600px)': {
                      flexDirection: 'row', // For tablets and desktops, display horizontally
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    image={item.image || b2bSoftwareImage}
                    alt={item.title}
                    sx={{
                      height: 180,
                      width: '100%',
                      borderRadius: '12px',
                      objectFit: 'cover',
                      marginBottom: '1rem',
                      '@media (min-width: 600px)': {
                        width: 180, // On larger screens, use fixed image size
                        marginBottom: 0,
                      },
                    }}
                  />

                  <Box sx={{ flexGrow: 1, textAlign: 'center', paddingLeft: '1rem' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                        color: '#333',
                        marginBottom: '0.5rem',
                        fontSize: '1.2rem',
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: '#555', marginBottom: '1rem', fontSize: '1rem' }}
                    >
                      {item.description}
                    </Typography>
                    <Typography variant="h6" sx={{ color: '#6200ea', fontWeight: 'bold' }}>
                      ₹{item.price}
                    </Typography>
                    <Divider sx={{ marginY: '1rem' }} />

                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          fontWeight: 'bold',
                          backgroundColor: '#6200ea',
                          '&:hover': {
                            backgroundColor: '#3700b3',
                          },
                          padding: '0.8rem 2rem',
                          fontSize: '1rem',
                        }}
                        onClick={handleBuyNow}
                      >
                        Make it yours
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          fontWeight: 'bold',
                          backgroundColor: '#e53935',
                          '&:hover': {
                            backgroundColor: '#b71c1c',
                          },
                          padding: '0.8rem 2rem',
                          fontSize: '1rem',
                        }}
                        onClick={() => handleRemoveFromCart(index)}
                      >
                        Remove Service
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ))}
            </List>
          )}
        </Box>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Service removed successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default Cart;
