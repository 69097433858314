import React from 'react';
import { Container, Typography, Box, Divider, Paper, Fade } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';

const RefundPolicy = () => {
  return (
    <>
      <Header />
      <Container maxWidth="lg" sx={{ my: 4, mt: 10 }}>
        <Paper 
          elevation={3} 
          sx={{
            p: 4,
            borderRadius: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(6px)',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            animation: 'fadeInUp 1s ease-in-out',
          }}
        >
          <Typography variant="h3" component="h1" gutterBottom color='#1A7BB2'>
            Refund Policy
          </Typography>

          <Divider sx={{ my: 2, backgroundColor: '#1A7BB2', height: '2px' }} />

          <Box sx={{ mb: 3 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              1. Refund Policy
            </Typography>
            <Box component="ul" sx={{ pl: 3 }}>
              <Fade in timeout={1000}>
                <li>
                  <Typography variant="body1">
                  Terms and Conditions are an Important part of a company. At deevapayon we want you to read Our Terms and Conditions and Privacy Policy without skipping any part of it. So you will know how we operate and what our Terms and Conditions and Privacy policy are, to avoid any misconception and to know what you are opting in for.
                  </Typography>
                </li>
              </Fade>
              <Fade in timeout={1200}>
                <li>
                  <Typography variant="body1">
                  The Terms and Conditions mentioned here applicable on all of our Products and Services including but not limited to accessing our Website and Mobile App. When You use our Products and Services including but not limited to our Website and Mobile App, You accept that you have read the Whole Terms and Conditions and Privacy Policy and agree with our Terms and Conditions and Privacy Policy.
                  </Typography>
                </li>
              </Fade>
              <Fade in timeout={1200}>
                <li>
                  <Typography variant="body1">
                  If you don’t agree with Whole or Some Parts of our Terms and Conditions, and Privacy Policy then you should not access our Website, Mobile App or any other Services that we provide. Without accepting these Terms and Conditions and Privacy Policy in whole, use of any Product and Services that we offer including but not limited to Our Website, Mobile App is strictly prohibited.
                  </Typography>
                </li>
              </Fade>
              <Fade in timeout={1200}>
                <li>
                  <Typography variant="body1">
                  The terms "We", "Us", "Ours", "Team" and "Company" used here should be Treated as "deevapayon". You are accepting Digital and Enforceable Terms and Conditions, Privacy policy of "deevapayon".
                  </Typography>
                </li>
              </Fade>
              <Fade in timeout={1200}>
                <li>
                  <Typography variant="body1">
                  We are a Digital Product and Service Provider Company. Made in India, Made by Indians and for everyone, anyone can enjoy and use our Range of Products and Services that we offer Directly or Through our Partners.
                  </Typography>
                </li>
              </Fade>
              <Fade in timeout={1200}>
                <li>
                  <Typography variant="body1">
                  We hold every rights on Our Company, Our Company Logo, Our TradeMark, Our Website, Our Mobile App, Contents and Pictures on our Website, Mobile App, and Services we offer.
                  </Typography>
                </li>
              </Fade>
              <Fade in timeout={1200}>
                <li>
                  <Typography variant="body1">
                  We may at any time stop or fail to Provide our Service(s) with or without any reason(s). We do not wish to do so but we can’t provide guarantees about it. If a situation like this occurs then we will not owe an explanation to anyone, neither will we be responsible for things that are not in our control or reach.
                  </Typography>
                </li>
              </Fade>
              <Fade in timeout={1200}>
                <li>
                  <Typography variant="body1">
                  Terms and Condition and Privacy Policy of deevapayon is subject to change from time to time as we grow or there is a need to update this. We may or may not Notify you about the Change of our Terms and Conditions and Privacy Policy depending on Whether these Change in Terms and Conditions and Privacy Policy will affect you or not
                  </Typography>
                </li>
              </Fade>
              <Fade in timeout={1200}>
                <li>
                  <Typography variant="body1">
                  WE ARE PROVIDING ALL THE SERVICES AS IS BASIS. WE DO NOT PROVIDE ANY GUARANTEE THAT OUR WEBSITE, MOBILE APP AND OTHER DIGITAL SERVICES WE PROVIDE WILL ALWAYS BE UP AND RUNNING. OUR WEBSITE AND SERVERS ARE HIGHLY ENCRYPTED STILL WE DO NOT PROVIDE ANY KIND OF GUARANTEE OR ASSURANCE THAT THERE WILL BE NO MISEVENT, WHERE SOMEONE OR BAD ACTORS MAY BREAK THE SECURITY.
                    <br/><br/>
                  WE ARE ALWAYS USING THE BEST ENCRYPTION SERVICE TO KEEP OUR WEBSITE, MOBILE APP AND OTHER DIGITAL SERVICES SECURE. BUT WITH THE RAPID CHANGE OF SOFTWARE AND TECHNOLOGY WE CAN’T ALWAYS BE SURE.
                  </Typography>
                </li>
              </Fade>
              <Fade in timeout={1200}>
                <li>
                  <Typography variant="body1">
                  We may make available Our Terms and Conditions, Privacy Policy and other content on another language for better readability and better understanding. So you may encounter some difference between English and Other language Content, cause the same thing is expressed in Different languages differently. THERE SHOULD BE NO DOUBT OF ANY KIND THAT WE HAVE MORE THAN ONE TERMS AND CONDITIONS, PRIVACY POLICY AND OTHER CONTENT.
                  </Typography>
                </li>
              </Fade>
              
            </Box>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              2. Our Digital Services:
            </Typography>
            <Typography variant="body1">
            We provide a range of Services and they are including but not limited to B2B Software Solutions,B2C Softwere, Android Application Development, Website Development, Graphic Design, Fintech Software Development, Cloud Solutions, CRM Solutions, E-Commerce Services, Salesforce Services. deevapayon Terms and Conditions and Privacy Policy applic, Software Development, Digital Marketing, API Services able on all Products and Services.
            </Typography>
          </Box>

          {/* Additional Sections */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              3. Restriction of Use:
            </Typography>
            <Typography variant="body1">
            You should not use all or any part of our Website, Mobile App and Our Services, If you did Fraud and Banned from using our services.
            <br/> <br/>
            Your local or National Authority doesn’t allow you to use our Website, Mobile App and Other Services.
            <br/> <br/>
            If you are not in legal age to use our Website, Mobile App and other Services.
            <br/> <br/>
            If you are using our Website, Mobile App and Our Services that means you don’t come under “Restriction of Use” and You agree with Our Terms and Conditions and Privacy Policy.
            </Typography>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              4. Third-party Links on Our Website and Mobile App:
            </Typography>
            <Box component="ul" sx={{ pl: 3 }}>
              <Fade in timeout={1600}>
                <li>
                  <Typography variant="body1">
                  We do not endorse any Third Party Website and Their Link but still You may encounter third party links on our Website. These links are Provided by their Respective Owners. We have no control over those Website Links nor we can Verify those links. WE PROVIDE THOSE LINKS TO YOU ON AS IS BASIS FOR GETTING OTHER SERVICES. You should check those links before proceeding to their Website and Your use of their Website will be Governed by their Respective Terms and Conditions and Privacy Policy.
                  </Typography>
                </li>
              </Fade>
              {/* More list items */}
            </Box>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              5. Your Rights:
            </Typography>
            <Typography variant="body1">
            We allow everyone who accepts Our Terms and Conditions and Privacy Policy to Access Our Website, Mobile App and to use Our Services. We give you LIMITED, NON-TRANSFERABLE, NON-SUBLICENSABLE, REVOCABLE rights.
            <br/><br/>
            Your rights to use our Services Including but not limited to Our Website, Mobile App may be Revoked or Restricted. If we found, you have Violated Our Terms and Conditions and Privacy Policy Or You don’t fulfil our requirements Or Depending on situation(s) at Our sole discretion that it is right to do so without giving you any explanation or reason(s).
            </Typography>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              6. Your Duty:
            </Typography>
            <Typography variant="body1">
            You accept and Warrant that you will not make a Copy of Our Website, Mobile App or Any Content, Image of this Website, Mobile App in Whole or Partial.
            <br/><br/><br/>
            You will not tamper our Website, Mobile App, Our Servers and Our Services in Whole or Partial. You will not Inject, Run or write Malicious Code or Scripts on Our Website, Mobile App and on Our Servers in Whole or Partial.
            <br/><br/>
            You will not do Frauds or try to do anything that will Harm including but not limited to Our Company, Our Website, Our Mobile App, Our Servers, and Our Products and Services.
            <br/><br/>
            If you Suspect or Encounter anyone doing or Planning about performing any of the above mentioned things You will inform us through Our Contact Number, Email, or you may Visit Our Office if it is near you.
            </Typography>
          </Box>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              7. Violation of Terms and Conditions:
            </Typography>
            <Typography variant="body1">
            If we find out by any means that you have Violated any of these Terms and Condition, We may at our sole discretion Block, Cancel, Restrict, Ban you without giving you an explanation from Using Our Website, Mobile App and from the Service we provide.
            <br/><br/><br/>
            Your Reuse of Our Website, Mobile App and Our Services may or may not continue depending upon which rule you broke and its impact on us.
            
            </Typography>
          </Box>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default RefundPolicy;
