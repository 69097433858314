// src/Services/StaticDynamicWebDevelopment.js
import React from 'react';
import { Box, Container, Typography, Grid, Button, Card, CardContent, Divider } from '@mui/material';
import { DesignServices, Code, Speed, Security, Build, TrendingUp } from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import webDevImage from '../assest/Static Dynamic Web Delvelopment.jpeg';

const StaticDynamicWebDevelopment = () => {
  return (
    <>
      <Header />
      <Box sx={{ backgroundColor: '#f9f9f9', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          {/* Hero Section */}
          <Box sx={{
            display: 'flex', flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center', gap: 3, mb: 6,
          }}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#1976d2', mb: 2 }}>
                Static & Fully Dynamic Web Development
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                Explore the best of both static and dynamic web solutions with Deeva Payon. Our team crafts robust, scalable websites tailored to suit any business model, ensuring excellent performance, security, and user engagement.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                sx={{ mt: 2 }}
                href="/contact"
              >
                Start Your Project
              </Button>
            </Box>
            <Box sx={{ flex: 1 }}>
              <img
                src={webDevImage}
                alt="Static and Dynamic Web Development"
                style={{ width: '100%', borderRadius: '10px' }}
              />
            </Box>
          </Box>

          {/* Core Services */}
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2', textAlign: 'center', mb: 3 }}>
            Our Core Services
          </Typography>
          <Grid container spacing={3}>
            {[
              { icon: <DesignServices fontSize="large" color="primary" />, title: 'Custom Design', desc: 'Bespoke designs tailored to your brand identity.' },
              { icon: <Code fontSize="large" color="primary" />, title: 'Code Optimization', desc: 'Efficient, clean coding for faster load times and easy maintenance.' },
              { icon: <Speed fontSize="large" color="primary" />, title: 'High Performance', desc: 'Optimized for speed, responsiveness, and reliability.' },
              { icon: <Security fontSize="large" color="primary" />, title: 'Enhanced Security', desc: 'Protect your data and user privacy with advanced security measures.' },
              { icon: <Build fontSize="large" color="primary" />, title: 'Dynamic CMS', desc: 'Fully integrated content management systems for complete control.' },
              { icon: <TrendingUp fontSize="large" color="primary" />, title: 'SEO-Ready', desc: 'Boost search visibility with optimized and accessible code.' }
            ].map((service, idx) => (
              <Grid item xs={12} md={4} key={idx}>
                <Card sx={{
                  transition: '0.3s',
                  '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' }
                }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      {service.icon}
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{service.title}</Typography>
                    </Box>
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                      {service.desc}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Benefits of Dynamic Web Development */}
          <Box sx={{ mt: 6 }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2', textAlign: 'center', mb: 3 }}>
              Why Choose Fully Dynamic Development?
            </Typography>
            <Typography variant="body1" color="textSecondary" textAlign="center" paragraph>
              Dynamic websites provide advanced features and capabilities, ideal for businesses that need regular updates and rich functionality.
            </Typography>
            <Grid container spacing={3} sx={{ mt: 3 }}>
              {[
                { title: 'Interactive User Experiences', desc: 'Engage users with real-time data updates and interactive features.' },
                { title: 'Data-Driven Solutions', desc: 'Easily connect to databases, manage content, and personalize user experiences.' },
                { title: 'Flexible & Scalable', desc: 'Adapt as your business grows, adding new functionalities as needed.' },
              ].map((benefit, idx) => (
                <Grid item xs={12} md={4} key={idx}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2', mb: 1 }}>
                    {benefit.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {benefit.desc}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Call to Action */}
          <Box sx={{ textAlign: 'center', mt: 6 }}>
            <Divider sx={{ mb: 3 }} />
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
              Ready to Elevate Your Online Presence?
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph sx={{ mt: 1 }}>
              Discover the power of a professional web presence with Deeva Payon. Get in touch for a consultation on how we can bring your vision to life.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              href="/contact"
              sx={{ mt: 2 }}
            >
              Contact Us
            </Button>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default StaticDynamicWebDevelopment;
