import React from 'react';
import { Box, Container, Typography, Grid, Card, CardContent, Button, Avatar, CardMedia } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import Header from '../components/Header';
import Footer from '../components/Footer';
// import TestimonialCarousel from '../components/TestimonialCarousel'; // Assuming you've implemented a carousel

const AboutUs = () => {
  return (
    <>
      <Header />
      {/* Hero Section */}
      <Box sx={{ backgroundImage: 'url(https://source.unsplash.com/featured/?tech,innovation)', padding: '4rem 0', color: '#fff', textAlign: 'center' }}>
        <Container maxWidth="lg">
          <Typography variant="h2" sx={{ fontWeight: 'bold' }}>About Deeva Payon</Typography>
          <Typography variant="h6" sx={{ marginTop: 2 }}>Empowering Businesses with Innovative Solutions</Typography>
        </Container>
      </Box>

      {/* Company Overview */}
      <Box sx={{ padding: '4rem 0', backgroundColor: '#f9f9f9' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 2 }}>Who We Are</Typography>
              <Typography variant="body1" color="textSecondary">
                Deeva Payon is a pioneering software development company that provides a wide range of services, including software development, B2B solutions, Micro ATM services, cloud solutions, and more. Our goal is to create custom solutions that drive business growth and deliver exceptional value to our clients.
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Established with a vision to revolutionize the digital space, we bring together expertise, creativity, and cutting-edge technologies to empower businesses of all sizes to thrive in the digital age.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                alt="Our office"
                image="https://source.unsplash.com/featured/?office,team"
                sx={{ borderRadius: 2 }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Core Values Section */}
      <Box sx={{ padding: '4rem 0' }}>
        <Container maxWidth="lg">
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2', textAlign: 'center', marginBottom: '4rem' }}>
            Our Core Values
          </Typography>
          <Grid container spacing={4}>
            {[
              { title: 'Innovation', text: 'We embrace new technologies and ideas to provide the best solutions.' },
              { title: 'Integrity', text: 'Honesty and transparency are at the heart of our operations.' },
              { title: 'Client-Centric', text: 'Our clients’ needs and success are our top priority.' },
              { title: 'Collaboration', text: 'We believe in the power of teamwork to achieve great results.' },
            ].map((value, index) => (
              <Grid item xs={12} md={3} key={index}>
                <Card sx={{ textAlign: 'center', padding: '2rem' }}>
                  <Avatar sx={{ backgroundColor: '#1976d2', marginBottom: '1rem', width: 56, height: 56 }}>
                    {/* Icons can be added here */}
                  </Avatar>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{value.title}</Typography>
                  <Typography variant="body2" color="textSecondary">{value.text}</Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Team Section */}
      <Box sx={{ padding: '4rem 0', backgroundColor: '#f9f9f9' }}>
        <Container maxWidth="lg">
          <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '4rem' }}>
            Meet Our Team
          </Typography>
          <Grid container spacing={4}>
            {[
              { name: 'John Doe', position: 'CEO', img: 'https://source.unsplash.com/featured/?man' },
              { name: 'Jane Smith', position: 'CTO', img: 'https://source.unsplash.com/featured/?woman' },
              { name: 'Mike Williams', position: 'Lead Developer', img: 'https://source.unsplash.com/featured/?developer' },
            ].map((member, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card sx={{ textAlign: 'center', padding: '2rem', boxShadow: 'none' }}>
                  <Avatar src={member.img} sx={{ width: 100, height: 100, margin: 'auto', marginBottom: '1rem' }} />
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{member.name}</Typography>
                  <Typography variant="body2" color="textSecondary">{member.position}</Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Achievements Section */}
      <Box sx={{ padding: '4rem 0' }}>
        <Container maxWidth="lg">
          <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '4rem' }}>
            Our Journey So Far
          </Typography>
          <Timeline position="alternate">
            {[
              { year: '2020', event: 'Founded Deeva Payon with a small but dedicated team.' },
              { year: '2021', event: 'Launched our first Micro ATM solution.' },
              { year: '2022', event: 'Expanded into cloud solutions and CRM development.' },
              { year: '2023', event: 'Became a leading provider of B2B software solutions.' },
            ].map((milestone, index) => (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  {index < 3 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{milestone.year}</Typography>
                  <Typography variant="body1" color="textSecondary">{milestone.event}</Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Container>
      </Box>

      {/* Testimonials Section */}
      <Box sx={{ padding: '4rem 0', backgroundColor: '#f9f9f9' }}>
        <Container maxWidth="lg">
          <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '4rem' }}>
            What Our Clients Say
          </Typography>
          {/* <TestimonialCarousel /> */}
        </Container>
      </Box>

      {/* Call to Action */}
      <Box sx={{ padding: '4rem 0', textAlign: 'center' }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2', marginBottom: '2rem' }}>
          Ready to Work with Us?
        </Typography>
        <Button variant="contained" color="secondary" size="large" href="/contact">
          Get in Touch
        </Button>
      </Box>
      <Footer />
    </>
  );
};

export default AboutUs;
