// src/Services/IOSDevelopment.js
import React from 'react';
import { Box, Container, Typography, Grid, Button, Card, CardContent, TextField } from '@mui/material';
import { Apple, Security, Speed, MobileFriendly } from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import iosImage from '../assest/iOS-app-development-company.png'; 

const IOSDevelopment = () => {
  return (
    <>
      <Header />
      <Box sx={{ backgroundColor: '#f9f9f9', padding: '5rem 0' }}>
        <Container maxWidth="lg">
          {/* Hero Section */}
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', gap: 3, mb: 4 }}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#1976d2', mb: 2 }}>
                iOS Development
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                At Deeva Payon, we offer specialized iOS app development services that bring your ideas to life with engaging, high-quality applications for the Apple ecosystem. From iPhone to iPad and wearable tech, our team designs apps that are intuitive, secure, and compatible with Apple's latest advancements.
              </Typography>
              <Button variant="contained" color="secondary" size="large" sx={{ mt: 3 }} href="/contact">
                Start Your Project
              </Button>
            </Box>
            <Box sx={{ flex: 1 }}>
              <img src={iosImage} alt="iOS Development" style={{ width: '100%', borderRadius: '10px' }} />
            </Box>
          </Box>

          {/* Key Features Section */}
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2', textAlign: 'center', mb: 3 }}>
            What We Offer
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Apple fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Native iOS Development</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                    We leverage Swift and Objective-C to create native applications that offer fast performance, smooth user experiences, and complete integration with Apple's ecosystem.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <MobileFriendly fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Cross-Platform Compatibility</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                    We also develop cross-platform solutions that work on both iOS and Android, ensuring a cohesive user experience across all devices.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Speed fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Optimized Performance</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                    Our apps are optimized for performance, delivering a smooth and seamless experience even under heavy usage.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Security fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Data Security and Privacy</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                    Security is paramount in our development process, with stringent data protection measures that adhere to Apple’s privacy standards.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Why Choose Us Section */}
          <Box sx={{ mt: 5, textAlign: 'center' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Why Choose Deeva Payon for iOS Development?</Typography>
            <Typography variant="body1" color="textSecondary" paragraph sx={{ mt: 1, mb: 3 }}>
              Our iOS development team combines technical expertise with a passion for user-centered design. We are committed to providing scalable and reliable solutions that meet your unique needs.
            </Typography>
            <Button variant="contained" color="secondary" size="large" href="/contact">Let’s Start Your iOS Project</Button>
          </Box>

          {/* Contact Form Section */}
          <Box sx={{ mt: 6, py: 4, backgroundColor: '#e3f2fd', borderRadius: '8px' }}>
            <Container maxWidth="sm">
              <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 2 }}>
                Get In Touch
              </Typography>
              <form>
                <TextField label="Name" fullWidth margin="normal" variant="outlined" />
                <TextField label="Email" fullWidth margin="normal" variant="outlined" />
                <TextField label="Phone" fullWidth margin="normal" variant="outlined" />
                <TextField label="Message" fullWidth multiline rows={4} margin="normal" variant="outlined" />
                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                  Submit
                </Button>
              </form>
            </Container>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default IOSDevelopment;
