import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import logo from "../assest/deevapayon_logo.png"; 

const PoweredBy = () => {
  return (
    <Box
      sx={{
        py: { xs: 4, md: 6 },
        px: { xs: 2, md: 4 },
        position: 'relative',
        border: '2px solid rgb(35, 140, 221)',
        background: 'linear-gradient(135deg, #f0f4ff, #d0e8ff)', 
        borderRadius: 3,
        textAlign: 'center',
      }}
    >
      {/* "Our Achievements" Heading */}
      <Typography
        variant="h4"
        sx={{
          mb: 4,
          fontWeight: 'bold',
          color: '#007BFF',
          fontSize: { xs: '1.8rem', md: '2.3rem' },
          textShadow: '1px 1px 4px rgba(0,0,0,0.5)',
          letterSpacing: 2,
        }}
      >
        Our Achievements
      </Typography>

      {/* Achievements Grid */}
      <Grid container spacing={3} justifyContent="center" sx={{ mb: { xs: 4, md: 6 } }}>
        {[
          { value: '5+', label: 'Years Experience' },
          { value: '1200+', label: 'Satisfied Clients' },
          { value: '950+', label: 'Active Softwares' },
          { value: '250+', label: 'Active Applications' },
          { value: '4.5', label: 'Clients Rating' },
          { value: '100+', label: 'Partners' },
          { value: '59+', label: 'Experts Team' },
        ].map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box
              sx={{
                textAlign: 'center',
                p: 3,
                borderRadius: 2,
                background: 'linear-gradient(145deg, #ffffff, #e6e6e6)', 
                boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)', 
                transition: 'transform 0.5s ease, box-shadow 0.5s ease',
                '&:hover': {
                  transform: 'translateY(-10px)', 
                  boxShadow: '0 12px 40px rgba(0, 0, 0, 0.3)', 
                  background: 'linear-gradient(145deg, #007BFF, #00A6FF)', 
                },
                '&:hover .card-number': {
                  color: '#fff', 
                },
                '&:hover .card-label': {
                  color: '#e0e0e0', 
                },
              }}
            >
              <Typography
                variant="h5"
                className="card-number"
                sx={{
                  fontWeight: 'bold',
                  color: '#FF6B6B',
                  fontSize: { xs: '1.8rem', md: '2.2rem' },  
                  transition: 'color 0.3s ease-in-out',
                }}
              >
                {item.value}
              </Typography>
              <Typography
                variant="subtitle1"
                className="card-label"
                sx={{
                  mt: 1,
                  color: '#333',
                  fontSize: { xs: '1rem', md: '1.1rem' }, 
                  transition: 'color 0.3s ease-in-out',
                }}
              >
                {item.label}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* "Powered By" Section */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          letterSpacing: 2,
          color: '#007BFF',
          textShadow: '1px 1px 3px rgba(0,0,0,0.3)',
          mb: 2,
          fontSize: { xs: '1.5rem', md: '2rem' },  
        }}
      >
        POWERED BY
      </Typography>

      {/* Logo Below "Powered By" */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            marginTop: '10px',
            width: '140px',  // Slightly larger logo
            height: '140px',
          }}
        />
      </Box>
    </Box>
  );
};

export default PoweredBy;
