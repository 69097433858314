// src/Services/SocialMediaMarketing.js
import React from 'react';
import { Box, Container, Typography, Grid, Button, Card, CardContent } from '@mui/material';
import { Campaign, People, TrendingUp, Insights } from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import socialMediaImage from '../assest/social-media-marketing.webp';

const SocialMediaMarketing = () => {
  return (
    <>
      <Header />
      <Box sx={{ backgroundColor: '#f9f9f9', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          {/* Hero Section */}
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', gap: 4, marginBottom: '3rem' }}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#1976d2', marginBottom: 2 }}>
                Social Media Marketing Services
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                At Deeva Payon, we provide end-to-end social media marketing services to help you connect with your audience, increase brand awareness, and boost conversions across major social platforms.
              </Typography>
              <Button variant="contained" color="secondary" size="large" sx={{ marginTop: 3 }} href="/contact">
                Enhance Your Online Presence
              </Button>
            </Box>
            <Box sx={{ flex: 1 }}>
              <img src={socialMediaImage} alt="Social Media Marketing" style={{ width: '100%', borderRadius: '10px' }} />
            </Box>
          </Box>

          {/* Services Offered */}
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2', textAlign: 'center', marginBottom: '2rem' }}>
            Our Social Media Marketing Solutions
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Campaign fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Campaign Management</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    From campaign strategy to execution, we handle every step to ensure your social media campaigns reach and engage your target audience effectively.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <People fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Audience Engagement</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    Build strong relationships with your audience through strategic engagement and consistent interaction across social platforms.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <TrendingUp fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Content Creation & Curation</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    We create captivating and relevant content tailored to your brand and audience, driving engagement and amplifying your message.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Insights fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Analytics & Reporting</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    Our data-driven approach includes comprehensive reporting and insights to optimize campaigns and track progress effectively.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Why Choose Us */}
          <Box sx={{ marginTop: '4rem', textAlign: 'center' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
              Why Choose Deeva Payon for Social Media Marketing?
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph sx={{ marginTop: '1rem', marginBottom: '3rem' }}>
              With our expertise in social media strategies, we help your brand stand out in a crowded digital space. Deeva Payon brings creativity, analytics, and dedication to build a strong, lasting online presence for your business.
            </Typography>
            <Button variant="contained" color="secondary" size="large" href="/contact">
              Let's Boost Your Brand Together
            </Button>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default SocialMediaMarketing;
