// src/components/Footer.js
import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton, Divider } from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#0d47a1',
        color: '#fff',
        padding: '4rem 0 2rem',
        borderTop: '4px solid #42a5f5',
        marginTop: 'auto',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          {/* Company Info Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: '#fff',
                position: 'relative',
                paddingBottom: '0.5rem',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  width: '50%',
                  height: '2px',
                  backgroundColor: 'red',
                },
              }}
            >
              Deeva Payon
            </Typography>
            <Typography variant="body2" sx={{ lineHeight: 1.8 }}>
              Deeva Payon is a leading provider of B2B, B2C, and white-label software solutions across India. Our expertise in software development and cloud solutions helps businesses thrive.
            </Typography>
          </Grid>

          {/* Quick Links Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: '#ffff',
                position: 'relative',
                paddingBottom: '0.5rem',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  width: '50%',
                  height: '2px',
                  backgroundColor: 'red',
                },
              }}
            >
              Quick Links
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', paddingLeft: 0 }}>
              <li>
                <Link href="/about" color="inherit" underline="hover" sx={{ display: 'block', marginBottom: '0.5rem' }}>
                  About Us
                </Link>
              </li>
              <li>
                <Link href="/career" color="inherit" underline="hover" sx={{ display: 'block', marginBottom: '0.5rem' }}>
                  Career
                </Link>
              </li>
              <li>
                <Link href="/blog" color="inherit" underline="hover" sx={{ display: 'block', marginBottom: '0.5rem' }}>
                  Blogs
                </Link>
              </li>
              <li>
                <Link href="/contact" color="inherit" underline="hover" sx={{ display: 'block', marginBottom: '0.5rem' }}>
                  Contact Us
                </Link>
              </li>
              <li>
                <Link href="/pages/faq" color="inherit" underline="hover" sx={{ display: 'block' }}>
                  FAQ
                </Link>
              </li>
            </Box>
          </Grid>

          {/* Services Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: '#ffff',
                position: 'relative',
                paddingBottom: '0.5rem',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  width: '50%',
                  height: '2px',
                  backgroundColor: 'red',
                },
              }}
            >
              Our Services
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', paddingLeft: 0 }}>
  <li>
    <Link href="/services/b2bsoftware" underline="none" color="inherit">
      <Typography variant="body2">B2B Software</Typography>
    </Link>
  </li>
  
               <li> <Link href="/services/B2CSoftwere" underline="none" color="inherit">
               <Typography variant="body2">B2C Softwere</Typography></Link></li>
               <li> <Link href="/services/apiservices" underline="none" color="inherit">
               <Typography variant="body2">API Services</Typography></Link></li>
               <li> <Link href="/services/fintechsoftweredevelopment" underline="none" color="inherit">
               <Typography variant="body2">FinTech Technology</Typography></Link></li>
  <li>
    <Link href="/services/andriodapp" underline="none" color="inherit">
      <Typography variant="body2">Application Development</Typography>
    </Link>
  </li>
  <li>
    <Link href="/services/websitedevelopment" underline="none" color="inherit">
      <Typography variant="body2">Website Development</Typography>
    </Link>
  </li>
  <li>
    <Link href="/services/softwaredevelopment" underline="none" color="inherit">
      <Typography variant="body2">Software Development</Typography>
    </Link>
  </li>

</Box>
          </Grid>

          {/* Contact Information Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: '#ffff',
                position: 'relative',
                paddingBottom: '0.5rem',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  width: '50%',
                  height: '2px',
                  backgroundColor: 'red',
                },
              }}
            >
              Contact Us
            </Typography>
            <Typography variant="body2" sx={{ lineHeight: 2 }}>
            Hans Bhawan, Mahal, Rana Sanga Marg, near Akshaya Patra Yojna, Sector 26, Jagatpura, Jaipur, Shri Kishanpura, Rajasthan 302017
            </Typography>
            <Typography variant="body2" sx={{ marginTop: '0.5rem', fontWeight: 'bold' }}>
              Phone: <Link href="tel:+919785909785" color="inherit">+91 9785909785</Link>
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Email: <Link href="mailto:info@deevapayon.com" color="inherit">info@deevapayon.com</Link>
            </Typography>
          </Grid>
        </Grid>

        {/* Divider */}
        <Divider sx={{ margin: '1rem 0', backgroundColor: '#42a5f5' }} />

        <Grid container spacing={6}>
          {/* Company Info Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: '#fff',
                position: 'relative',
                paddingBottom: '0.5rem',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  width: '50%',
                  height: '2px',
                  backgroundColor: 'red',
                },
              }}
            >
              Application Development
            </Typography>
            <Box sx={{ listStyle: 'none', paddingLeft: 0 }}>
              <li> <Link href="/services/andriodapp" underline="none" color="inherit">
      <Typography variant="body2">Andriod Development</Typography>
    </Link></li>
              <li> <Link href="/services/FlutterDevelopment" underline="none" color="inherit">
      <Typography variant="body2">Flutter Development</Typography>
    </Link></li>
              <li> <Link href="/services/IOSDevelopment" underline="none" color="inherit">
      <Typography variant="body2">IOS Development</Typography>
    </Link></li>
            </Box>
          </Grid>

          {/* Quick Links Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: '#ffff',
                position: 'relative',
                paddingBottom: '0.5rem',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  width: '50%',
                  height: '2px',
                  backgroundColor: 'red',
                },
              }}
            >
              Website Development
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', paddingLeft: 0 }}>
              <li>
                <Link href="/services/WordPressDevelopment" color="inherit" underline="hover" sx={{ display: 'block', marginBottom: '0.5rem' }}>
                  Wordpress Development
                </Link>
              </li>
              <li>
                <Link href="/services/StaticDynamicWebDevelopment" color="inherit" underline="hover" sx={{ display: 'block', marginBottom: '0.5rem' }}>
                Static/Fully Dynamic Web Development 
                </Link>
              </li>
              <li>
                <Link href="/services/BlogWebsiteDevelopment" color="inherit" underline="hover" sx={{ display: 'block', marginBottom: '0.5rem' }}>
                Blog Website Development
                </Link>
              </li>
            </Box>
          </Grid>

          {/* Services Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: '#ffff',
                position: 'relative',
                paddingBottom: '0.5rem',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  width: '50%',
                  height: '2px',
                  backgroundColor: 'red',
                },
              }}
            >
              Digital Marketing
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', paddingLeft: 0 }}>
  <li>
    <Link href="/Services/SocialMediaMarketing" underline="none" color="inherit">
      <Typography variant="body2">Social Media Marketing</Typography>
    </Link>
  </li>
  <li>
    <Link href="/services/graphic-design" underline="none" color="inherit">
      <Typography variant="body2">Graphic Design</Typography>
    </Link>
  </li>
  <li>
    <Link href="/Services/seo" underline="none" color="inherit">
      <Typography variant="body2">Search Engine Optimization</Typography>
    </Link>
  </li>
  <li>
    <Link href="/services/googleads" underline="none" color="inherit">
      <Typography variant="body2">Google ADS</Typography>
    </Link>
  </li>
  <li>
    <Link href="/services/metaads" underline="none" color="inherit">
      <Typography variant="body2">Meta ADS</Typography>
    </Link>
  </li>

  
</Box>
          </Grid>

          {/* Contact Information Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: '#ffff',
                position: 'relative',
                paddingBottom: '0.5rem',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  width: '50%',
                  height: '2px',
                  backgroundColor: 'red',
                },
              }}
            >
              Other Services
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', paddingLeft: 0 }}>
              <li> <Link href="/PrivacyPolicy" underline="none" color="inherit">
               <Typography variant="body2">Privacy Policy</Typography></Link></li>
               <li> <Link href="/RefundPolicy" underline="none" color="inherit">
               <Typography variant="body2">Refund Policy</Typography></Link></li>
               <li> <Link href="/Term&Condition" underline="none" color="inherit">
               <Typography variant="body2">Terms & Condition</Typography></Link></li>
            </Box>
          </Grid>
        </Grid>

        {/* Divider */}
        <Divider sx={{ margin: '3rem 0', backgroundColor: '#42a5f5' }} />

        {/* Social Links and Copyright */}
        <Box textAlign="center" mt={4}>
        <Box>
  <IconButton 
    href="https://www.facebook.com/people/Deeva-Payon-Global-Pvt-Ltd/61565814323246/" 
    sx={{ color: '#fff', '&:hover': { color: '#42a5f5' } }} 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <Facebook fontSize="large" />
  </IconButton>
  
  <IconButton 
    href="https://x.com/deevapayon" 
    sx={{ color: '#fff', '&:hover': { color: '#42a5f5' } }} 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <Twitter fontSize="large" />
  </IconButton>
  
  <IconButton 
    href="https://www.instagram.com/deevapayon/" 
    sx={{ color: '#fff', '&:hover': { color: '#42a5f5' } }} 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <Instagram fontSize="large" />
  </IconButton>
  
  <IconButton 
    href="https://www.linkedin.com/company/deevapayon-private-limited" 
    sx={{ color: '#fff', '&:hover': { color: '#42a5f5' } }} 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <LinkedIn fontSize="large" />
  </IconButton>
</Box>

          <Typography variant="body2" sx={{ marginTop: '1rem', fontWeight: 'bold' }}>
            &copy; 2024 Deeva Payon. All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
