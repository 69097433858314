// src/Services/GoogleAds.js
import React from 'react';
import { Box, Container, Typography, Grid, Button, Card, CardContent } from '@mui/material';
import { Campaign, Analytics, MonetizationOn, TrendingUp } from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import googleAdsImage from '../assest/Google ADS.webp';

const GoogleAds = () => {
  return (
    <>
      <Header />
      <Box sx={{ backgroundColor: '#f9f9f9', padding: '3rem 0' }}>
        <Container maxWidth="lg">
          {/* Hero Section */}
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', gap: 4, marginBottom: '1rem' }}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#1976d2', marginBottom: 2 }}>
                Google Ads Services
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                At Deeva Payon, we offer expert Google Ads management that maximizes your return on investment. Our data-driven approach ensures effective ad placement, targeting, and budgeting to reach your ideal audience.
              </Typography>
              <Button variant="contained" color="secondary" size="large" sx={{ marginTop: 3 }} href="/contact">
                Launch Your Campaign Today
              </Button>
            </Box>
            <Box sx={{ flex: 1 }}>
              <img src={googleAdsImage} alt="Google Ads Services" style={{ width: '100%', borderRadius: '10px' }} />
            </Box>
          </Box>

          {/* Services Offered */}
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2', textAlign: 'center', marginBottom: '2rem' }}>
            Our Google Ads Solutions
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Campaign fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Campaign Management</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    We develop and manage ad campaigns that align with your business goals and adjust strategies based on performance data.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <TrendingUp fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Performance Optimization</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    Our team continuously refines ads, keywords, and bidding to optimize for cost-efficiency and high returns.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Analytics fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Analytics & Reporting</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    Gain insight into your ad performance through detailed reports, helping you understand traffic, conversions, and ROI.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ transition: '0.3s', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <MonetizationOn fontSize="large" sx={{ color: '#1976d2' }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Budget Management</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    We help you make the most of your ad budget by strategically allocating funds to the highest-performing campaigns.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Why Choose Us */}
          <Box sx={{ marginTop: '4rem', textAlign: 'center' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
              Why Choose Deeva Payon for Google Ads?
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph sx={{ marginTop: '1rem', marginBottom: '3rem' }}>
              With years of experience in digital advertising, our team designs effective, targeted campaigns that convert clicks into customers. We ensure your business gets visibility, and we provide ongoing optimization to drive results.
            </Typography>
            <Button variant="contained" color="secondary" size="large" href="/contact">
              Start Your Google Ads Journey
            </Button>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default GoogleAds;
